<template>
  <div>
      <v-row>
          <v-col cols="12"
              md="4"
              sm="6"
              v-for="(item, index) in allTees"
              :key="index">
              <div class="card">
                <img :src="item.image" alt="">
                <p>{{item.description}}</p>
              </div>
          </v-col>

      </v-row>
  </div>
</template>

<script>
import {teesOne, teesTwo, teesThree} from "@/assets/cloudinary";

export default {
    name:"Hoodies",
    data(){
        return{
            allTees:[
                {
                    image: teesThree,
                    description:"V-neck - Short Sleeve"
                },
                {
                    image: teesOne,
                    description:"Round Neck - Short sleeve"
                },
                {
                    image: teesTwo,
                    description:"Round Neck - Long sleeve"
                },
                {
                    image: teesThree,
                    description:"V-neck - Short Sleeve"
                },
                {
                    image: teesThree,
                    description:"V-neck - Short Sleeve"
                },
            ]
        }
    }

}
</script>

<style lang="scss" scoped>
.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
</style>