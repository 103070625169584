<template>
  <section class="section">
    <div class="main">
      <div>
        <h3 class="tw-mb-3">Categories</h3>
        <div class="tw-flex sm:tw-hidden">
          <v-expansion-panels flat accordion>
            <v-expansion-panel
              class="panel"
              v-for="(tab, index) in tabs"
              :key="index"
            >
            <v-expansion-panel-header style="border-bottom: 1px solid #F4F6F7;">
                <div class="text-capitalize tw-flex tw-items-center">
                    <img class="mr-2" :src="tab.icon" alt="" />
                    <span>{{ tab.name }}</span>
                    <small v-if="tab.hasAsterisk" style="color: #FF7A01">*</small>
                  </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
          <keep-alive>
                <component :is="returnMainContent()"></component>
              </keep-alive>
        </v-expansion-panel-content>
                
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div class="tw-hidden sm:tw-flex">
          <v-row>
            <v-col sm="4" md="2" class="colOne">
              <v-tabs hide-slider vertical v-model="active_tab">
                <v-tab
                  v-for="(tab, i) in tabs"
                  :key="i"
                  @click="switchTab(tab.name)"
                >
                  <div class="text-capitalize tw-flex tw-items-center">
                    <img class="mr-2" :src="tab.icon" alt="" />
                    <span>{{ tab.name }}</span>
                    <small v-if="tab.hasAsterisk" style="color: #FF7A01">*</small>
                  </div>
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col sm="8" md="10">
              <keep-alive>
                <component :is="returnMainContent()"></component>
              </keep-alive>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Hoodies from "./Hoodies.vue";
import Tees from "./Tees.vue";
export default {
  components: { Tees, Hoodies },
  name: "CategoriesPageWrapper",
  data() {
    return {
      active_tab: 0,
      mode: "Tees",
      tabs: [
        {
          id: 0,
          name: "Tees",
          icon: require("@/assets/landingPage/categories/tees_icon.svg"),
          hasAsterisk: false
        },
        {
          id: 1,
          name: "Sweat Shirt",
          icon: require("@/assets/landingPage/categories/tees_icon.svg"),
          hasAsterisk: true
        },
        {
          id: 2,
          name: "Hoodies",
          icon: require("@/assets/landingPage/categories/tees_icon.svg"),
          hasAsterisk: true
          
        },
        {
          id: 3,
          name: "Caps",
          icon: require("@/assets/landingPage/categories/cap_icon.svg"),
          hasAsterisk: true
        },
      ],
    };
  },
  created() {
    this.active_tab = 0;
  },
  methods: {
    switchTab(name) {
      this.mode = name;
      console.log(this.active_tab);
    },
    returnMainContent() {
      if (this.mode === "Hoodies") {
        //   this.active_tab = 0
        return "Hoodies";
      } else if (this.mode === "Sweat Shirt") {
        //   this.active_tab = 1
        return "Tees";
      } else if (this.mode === "Caps") {
        //   this.active_tab = 1
        return "Tees";
      } else {
        this.active_tab = 0;
        return "Tees";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  max-width: 1600px;
  margin: auto;
}
.main {
  margin: 100px auto;
  width: 85%;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #322412;
  }
}
.main ::v-deep .v-tab {
  justify-content: start;
  border-bottom: 1px solid #F4F6F7;
}
.main ::v-deep .v-tab--active{
  background: #F4F3F3;
  border-bottom: 1px solid #F4F6F7;
  border-radius: 300px;
}
.main ::v-deep .v-tab.v-tab{
  color: transparent;
}
.colOne {
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #322412;
    letter-spacing: 0;
  }
}
</style>