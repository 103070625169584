<template>
  <div>
      <categories-page-banner/>
      <categories-page-wrapper/>
  </div>
</template>

<script>
import CategoriesPageBanner from '../components/categoriesPage/CategoriesPageBanner.vue'
import CategoriesPageWrapper from '../components/categoriesPage/CategoriesPageWrapper.vue'
export default {
  components: { CategoriesPageBanner, CategoriesPageWrapper },
    name:"Categories"

}
</script>

<style>

</style>