<template>
<section class="section">
  <div class="main">
    <div class="banner_container">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          class="tw-flex tw-items-center column_one tw-justify-center"
        >
          <div class="tw-p-5">
              <div class="tw-mb-2">
              <h4>BEST SELLING</h4>
              </div>
            <h1>Classic Tees</h1>
            <h5>
              Get our best-selling cotton T-shirts; customise and bee-u-kneenk.
            </h5>
            <div class="tw-flex tw-items-center tw-mt-4">
              <v-btn to="/build" text class="button">
                Create Merch Now
                <v-icon size="18" color="#FFFFFF" class="tw-pl-1">mdi-arrow-top-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="tw-flex tw-items-center column_two tw-justify-center"
        >
          <img :src="image" alt="classic_tees" />
        </v-col>
      </v-row>
      
    </div>
  </div>
  </section>
</template>

<script>
import {classicTees} from "@/assets/cloudinary";

export default {
  name:"CategoriesPageBanner",
  data(){
    return{
      image: classicTees
    }
  }
};
</script>

<style lang="scss" scoped>
.section{
    max-width: 1600px;
  margin: auto;
}
.main {
  margin: 100px 0 ;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #322412;
    @media (max-width: 767px) {
      font-size: 38px;
    }
  }
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #322412;
    max-width: 316.67px;
    padding: 16px 0;
    @media (max-width: 767px) {
    max-width: none;
    }
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #322412;
  }
  h4{
      background: #FFFFFF;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.12em;
    color: #322412;
    padding: 10px;
    width: fit-content;
    @media (max-width: 767px) {
    max-width: none;
    }

  }
}
.banner_container {
  width: 85%;
  background: #f9f4ee;
  border-radius: 64px;
  margin: auto;
  position: relative;
}
.button {
  width: 247px;
  height: 54px !important;
  background: #ff7a01;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: white;
  background: #ff7a01;
  box-shadow: 0px 24px 40px #e5ceb3;
  border-radius: 16px;
}
.column_two {
  img {
    padding:30px;
//   @media (max-width: 959px) {
//     margin-top: 0;
//   }
  }
}
.column_one div {
  max-width: 400px;
  @media (max-width: 767px) {
    display: flex;
    max-width: none;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

  }
}
</style>